import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';

import RequestService from '../../services/RequestService';
import EditableContent from '../../components/EditableContent';
import config from '../../config';
import ResourceList from '../resourceList/ResourceList';
import BreadCrumb from '../../components/BreadCrumb//BreadCrumb';
// import GameSurvey from './GameSurvey';
import Login from '../login/Login';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

// 2 minutes
const saveInterval = 120000;

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameModalOpen: false,
      showGameModalCloseIcon: true,
      topicItems: props.topicItems || null,
      readyToPlay: props.authStore.loggedIn,
      needCertificate: false,
      // showSurvey: false,
      ignoredOrCompletedSurvey: false,
    };
  }

  getGameId = () => {
    const {gamesStore, match} = this.props;
    return gamesStore.getGameIdFromParam(match.params.id);
  };

  autoSave = async () => {
    const {authStore, gameSessionsStore} = this.props;
    this.state.gameModalOpen &&
      authStore.loggedIn &&
      (console.log('Saving game...') || gameSessionsStore.updateSession(authStore.userId, this.getGameId()));
  };

  componentDidMount() {
    const {authStore, gamesStore, gameSessionsStore, history} = this.props;
    const gameId = this.getGameId();
    authStore.isEditor && gamesStore.resetGamesStore();
    gamesStore.getGames();
    if (authStore.loggedIn) {
      gameSessionsStore.setSession(authStore.userId, gameId);
    }
    !this.state.topicItems &&
      RequestService.get(
        `${config.apiGateway.URL}/game/${gameId}/topic-items`,
        (resp) => {
          this.setState({
            topicItems: resp.data,
          });
        },
        (error) => console.log(error),
      );

    const openGame = queryString.parse(history.location.search)['openGame'] === 'true';
    this.setState(
      {
        gameModalOpen: openGame,
      },
      () => {
        if (openGame) {
          gameSessionsStore.createSession(authStore.userId, gameId);
          history.push({search: ``});
        }
      },
    );

    window.updateSession = (sessionKey, sessionValue) => {
      gameSessionsStore.saveSession(sessionKey, sessionValue, authStore.userId, gameId);
    };
    // To be called by the game when the close button is clicked inside the game
    window.closeGame = () => {
      // this.setState({
      //   showSurvey: true,
      // });
      gameSessionsStore.clearSession();
    };
    window.sendCompleteRequest = () => {
      if (authStore.loggedIn) {
        RequestService.post(
          `${config.apiGateway.URL}/game-sessions/complete`,
          {game_id: gameId},
          () => {
            // when user completes, let's update the stored session so we can
            //  access the data if necessary. let's also hide the modal close
            //  button and and display the link to the survey (effectively,
            //  just change the close button into the survey link...same
            //  exact functionality). also, wipe localStorage
            gameSessionsStore.getSessions(authStore.userId);
            this.setState({showGameModalCloseIcon: false});
            localStorage.clear();
            console.log('Successfully completed game.');
          },
          () => console.log('Failed to complete game.'),
        );
      } else {
        gameSessionsStore.completeAnonymousGame();
      }
    };
    setInterval(this.autoSave, saveInterval);
  }

  toggleGameModal = (e) => {
    e.preventDefault();
    const {gameModalOpen} = this.state;
    const {authStore, gameSessionsStore} = this.props;
    const gameId = this.getGameId();
    authStore.loggedIn &&
      (gameModalOpen
        ? gameSessionsStore.updateSession(authStore.userId, gameId)
        : gameSessionsStore.createSession(authStore.userId, gameId));
    this.setState({
      gameModalOpen: !gameModalOpen,
      readyToPlay: authStore.loggedIn,
    });
  };

  renderGameModal = (game) => {
    const isSpanish = this.props.i18n.language === 'es';
    let closeButtonClasses = 'game-modal-close-button';
    closeButtonClasses += isSpanish ? ` ${closeButtonClasses}-es` : '';
    return (
      <Modal
        open={this.state.gameModalOpen}
        onClose={this.toggleGameModal}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={this.state.showGameModalCloseIcon}
        classNames={{
          // modal: `${!this.state.readyToPlay && "login"} game-modal ${
          //   this.state.showSurvey && "survey"
          // }`,
          modal: `${!this.state.readyToPlay && "login"} game-modal`,
          closeButton: closeButtonClasses,
        }}
      >
        {this.renderGame(game)}
      </Modal>
    );
  };

  renderCertificatePrompt = (game) => {
    const {gameSessionsStore, authStore, t, i18n} = this.props;
    const gameId = this.getGameId();
    const imageName = `${game.name}${i18n.language === 'es' ? '-es' : ''}`;
    const imageUrl = require(`../../assets/images/${imageName}.png`);
    return (
      <div className='padding-top-2 padding-bottom-2'>
        <div>
          {this.state.needCertificate ? (
            <Login
              {...this.props}
              loginMessage={<h2>Login</h2>}
              afterLogin={() => {
                gameSessionsStore.createSession(authStore.userId, gameId);
                this.setState({readyToPlay: true});
              }}
              preventRedirect
            />
          ) : (
            <div className='registration-wrapper margin-x-auto'>
              <form>
                <fieldset className='text-align-center'>
                  <div className='grid-row display-inline-block margin-0-auto hide-mobile'>
                    <img className='game-image' alt='Game' src={imageUrl} height='250' />
                  </div>
                  <div className='grid-col-12 display-inline-block margin-0-auto'>
                    <h2 className='earn-a-certificate'>
                      {t('Do you want to earn a certificate or save your progress?')}
                    </h2>
                  </div>
                  <div className='display-inline-block margin-0-auto'>
                    <button onClick={() => this.setState({needCertificate: true})} className='usa-button'>
                      {t('Yes')}
                    </button>
                    <button
                      onClick={() => {
                        gameSessionsStore.createSession('anonymous', gameId);
                        this.setState({readyToPlay: true});
                      }}
                      className='usa-button usa-button--outline play-game-without-certificate'
                    >
                      {t('No')}
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderGame = (game) => {
    const {i18n} = this.props;
    // const {surveyStore, t, i18n} = this.props;
    // if (this.state.showSurvey)
    //   return (
    //     <GameSurvey
    //       closeSurvey={() =>
    //         this.setState({
    //           gameModalOpen: false,
    //           // showSurvey: false,
    //           showGameModalCloseIcon: true,
    //           ignoredOrCompletedSurvey: true,
    //         })
    //       }
    //       game={game}
    //       surveyStore={surveyStore}
    //     />
    //   );

    if (this.state.readyToPlay)
      return (
        <>
          {!this.state.showGameModalCloseIcon && (
            <div
              style={{
                position: 'absolute',
                top: '1rem',
                height: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {/* <button
                className='usa-button'
                style={{position: 'relative', top: '0'}}
                onClick={() => this.setState({showSurvey: true})}
              >
                {t('Take survey')}
              </button> */}
            </div>
          )}
          <iframe
            title='Money Smart Game'
            className='game-frame'
            frameBorder='0'
            height='100%'
            width='100%'
            src={i18n.language === 'es' ? game.url_es : game.url}
          />
        </>
      );
    return this.renderCertificatePrompt(game);
  };

  render() {
    const {gamesStore, authStore, history, match, t, i18n} = this.props;
    const game = gamesStore.getGame(match.params.id);
    if (!gamesStore.gamesLoading && game === undefined) {
      window.location = '/games';
    }
    game && config.setUniqueTitle(game.name);

    if (gamesStore.gamesLoading) return null;

    const isSpanish = i18n.language === 'es';
    // editable content vars
    const ecNameFieldName = isSpanish ? 'name_es' : 'name';
    const ecDetailsFieldName = isSpanish ? 'details_es' : 'details';
    const ecContentsFieldName = isSpanish ? 'contents_es' : 'contents';
    const ecNameInitialContent = isSpanish ? game.name_es || game.name : game.name;
    const ecDetailsInitialContent = isSpanish ? game.details_es || game.details : game.details;
    const ecContentsInitialContent = isSpanish ? game.contents_es || game.contents : game.contents;
    const gameName = isSpanish ? game.name_es : game.name;
    const gameContents = isSpanish ? game.contents_es : game.contents;

    const updateEndpoint = `${config.apiGateway.URL}/game/${game.id}`;
    const gameUrl = isSpanish ? game.url_es : game.url;
    const imageName = `${game.name}${isSpanish ? '-es' : ''}`;
    const imageUrl = require(`../../assets/images/${imageName}.png`);

    return (
      <div className='game-page category-details'>
        <BreadCrumb
          includeHome
          historyLinks={[
            {
              link: '/games',
              display: 'Games',
            },
            {
              link: '#',
              display: gameName,
            },
          ]}
        />
        {authStore.isOrganization && (
          <div className='usa-alert usa-alert--warning margin-top-3'>
            <div className='usa-alert__body'>
              <p className='usa-alert__text'>
                You may play this game while logged into this account, but your progress will not affect statistics
                displayed in your reports.
              </p>
            </div>
          </div>
        )}
        <div>
          <div className='grid-row'>
            <EditableContent
              htmlType='h1'
              initialContent={ecNameInitialContent}
              fieldName={ecNameFieldName}
              updateEndpoint={updateEndpoint}
              canEdit={authStore.isEditor}
              fullWidth
            />
          </div>
          <div className='margin-bottom-2'>
            <LanguageSelector {...this.props} />
          </div>
          <div className='margin-bottom-2'>
            <span className='font-size-14'>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              {t('This ')}
              <a href='#' onClick={this.toggleGameModal}>
                {t('game')}
              </a>
              {t(' and its ')}
              <a href='#resources'>{t('resources')}</a>{' '}
              <EditableContent
                htmlType='p'
                initialContent={ecDetailsInitialContent}
                fieldName={ecDetailsFieldName}
                updateEndpoint={updateEndpoint}
                canEdit={authStore.isEditor}
                fullWidth
              />
            </span>
            {/* <ManagingDebtGameMessage isSpanish={isSpanish} gameName={game.name} /> */}
          </div>
          <div className='grid-row'>
            <div className='grid-col-12'>
              <div className='grid-row'>
                <div className='desktop:grid-col-6 mobile:grid-col game-graph'>
                  <div className='game-image-container margin-right-2'>
                    <a
                      className='usa-link game-image-link'
                      title={`${t('individualGame.playButton')}: ${gameName}`}
                      href='#'
                      onClick={this.toggleGameModal}
                    >
                      <img className='game-image' alt={t('game')} src={imageUrl} />
                      <div className='play-game-banner-button'>
                        <span>
                          <Trans i18nKey='individualGame.playButton'>Play this game now</Trans>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='grid-col-6 game-content mobile-full-width'>
                  <h3 className='no-margin'>
                    <Trans i18nKey='individualGame.contents'>Game Contents and Estimated Times</Trans>
                  </h3>
                  <div className='text-align-left full-width'>
                    {!this.props.authStore.isEditor ? (
                      <div dangerouslySetInnerHTML={{__html: gameContents}}></div>
                    ) : (
                      <EditableContent
                        htmlType='div'
                        initialContent={ecContentsInitialContent}
                        fieldName={ecContentsFieldName}
                        updateEndpoint={updateEndpoint}
                        richText
                        canEdit={authStore.isEditor}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='grid-col-12'>
            <h2 id='resources'>
              <Trans i18nKey='individualGame.resourcesSectionHeader'>Resources</Trans>
            </h2>
            <p>
              <Trans i18nKey='individualGame.resourcesNote'>
                <b>Note:</b> You don’t need an account to use these Resources. Information you enter in the Tools will
                not be saved to an account.
              </Trans>
            </p>
          </div>
          <div className='grid-row acc-tabs'>
            {
              <ResourceList
                topics={this.state.topicItems}
                canEdit={authStore.isEditor}
                history={history}
                authStore={authStore}
              />
            }
          </div>
        </div>
        {this.renderGameModal(game)}
        {/* Test adding hidden iframe to "preload" the game since it seems to work once game is cached. */}
        <iframe title='preload-iframe' style={{display: 'none'}} src={gameUrl} />
      </div>
    );
  }
}

export default withTranslation()(observer(Game));

/////////////////////////////////////////////////
// TEMP COMPONENT UNTIL Managing Debt IS UPDATED:
/////////////////////////////////////////////////
// const ManagingDebtGameMessage = ({gameName, isSpanish}) => {
//   if (gameName !== 'Managing Debt') return null;
//   const line1 = isSpanish ? '****Importante: a partir del 1 de julio de 2022' : '****IMPORTANT, Effective 7/1/2022:';
//   const line2 = isSpanish ? (
//     <span>
//       Las tres agencias de informes de crédito a nivel nacional no incluirán las deudas médicas impagas en los informes
//       de crédito hasta un año después de la fecha en que son declaradas deudas morosas. Antes ese plazo era de 180 días.
//       Los informes de crédito también dejarán de incluir las deudas médicas en los cobros que se ahora se pagan.
//     </span>
//   ) : (
//     <span>
//       The three nationwide credit reporting agencies will not include unpaid medical debt in consumer credit reports
//       until one year after it first became delinquent. That timeframe used to be 180 days. They will also stop including
//       medical debt in collections that is now paid.
//     </span>
//   );
//   const line3 = isSpanish ? (
//     <span>
//       Estamos actualizando este juego para reflejar esos cambios. Actualmente <strong>no incluye</strong> estos cambios.
//     </span>
//   ) : (
//     <span>
//       We are updating this game to reflect those changes. It currently <strong>does not include</strong> these changes.
//     </span>
//   );
//   return (
//     <>
//       <ManagingDebtGameUpdateMessage isSpanish={isSpanish} />
//       <div className='margin-bottom-3'>
//         <p className='margin-top-1 margin-bottom-05'>
//           <strong>{line1}</strong>
//         </p>
//         <p className='margin-y-05'>{line2}</p>
//         <p className='margin-y-05'>{line3}</p>
//       </div>
//     </>
//   );
// };
